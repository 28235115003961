import React from "react"
import Layout from "../components/common/layout"
import Meta from "../components/common/meta"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout page="error-404">
    <Meta
      title="Not Found | 1moku co."
      description="hirofumi sugaを中心に庭園デザインをはじめ、庭師の視点でとらえた空間デザイン、ランドスケープデザインなどを手掛けている1moku co.についてご紹介します。"
      url="https://www.1moku.co.jp/404/"
      type="article"
    />
    <div className="error-page-title">
      <p className="error-page-title__t">
        <span className="error-page-title__in">404</span>
      </p>
    </div>
    <div className="error-404-container">
      <h1 className="title">
        <span className="en">404 File not found.</span>
        <span className="ja">お探しのページが見つかりません。</span>
      </h1>
      <p className="text ja">
        一時的にアクセスできない状況にあるか、
        <br />
        移動もしくは削除された可能性があります。
      </p>
      <div className="btn">
        <Link className="btn__link en" to="/">
          top
        </Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
